@import "./../basedata/variables";

.form-check-input:checked {
  background-color: var(--pm-primary);
  border-color: var(--pm-primary); }

select.form-control {
  font-size: 16px; }


.form-group {
  margin-bottom: 20px; }
